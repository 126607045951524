import * as React from 'react'
import WpAcfHeaderprimaryBlock from '@modules/WpAcfHeaderprimaryBlock'
import { useStaticQuery, graphql } from 'gatsby'

// markup
const MaintenancePage = () => {
	const data = useStaticQuery(graphql`
		{
			wp {
				prototyprOptions {
					optionsIntroHeader {
						backgroundImage {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
							altText
						}
					}
				}
			}
		}
	`)
	return (
		<>
			<WpAcfHeaderprimaryBlock
				data={{ modulesHeaderPrimary: { image: data.wp.prototyprOptions.optionsIntroHeader.backgroundImage, headline: 'Page will be live soon' } }}
				locale={'de'}
				overline={'Try again soon'}
			/>
		</>
	)
}

export default MaintenancePage
